import apiRequest from '../../middlewares/api-request'
import axios from 'axios'
import { setCookie } from '@/utils/cookies'

const signup = {
  state: {
    csrf: '',
    token: localStorage.getItem('token') !== undefined ? localStorage.getItem('token'): null,
    user_id: localStorage.getItem('user_id') !== undefined ? localStorage.getItem('user_id') : null,
    cards: [],
    step: 0,
    availableFileTypes: [],
    authClient: ![null, undefined, 'false'].includes(localStorage.getItem('auth:client')),
    permissions: [],
  },
  mutations: {
    SET_CSRF_TOKEN: (state, res) => {
      state.csrf = res
    },
    SET_TOKEN: (state, token) => {
      state.token = token
      localStorage.setItem('token', token)
      setCookie('token', token, 30)
    },
    UNSET_CLIENT_CARD ( state ) {
      state.cards = []
    },
    CLEAR_TOKEN: (state) => {
      state.token = null
      localStorage.removeItem('token')
      setCookie('token', '', -1)
    },
    SET_CLIENT_ID: (state, clientId) => {
      state.user_id = clientId
      localStorage.setItem('user_id', clientId)
      setCookie('user_id', clientId, 30)
    },
    CLEAR_CLIENT_ID: (state) => {
      state.user_id = null
      localStorage.removeItem('user_id')
      setCookie('user_id', '', -1)
    },
    SET_CARD: (state, { data }) => {
      state.cards.push(data.card)
      state.cards = [...new Set(state.cards.map(JSON.stringify))].map(
        JSON.parse
      )
    },
    SET_STEP: (state, { data }) => {
      state.step = data.data['step_data'] ? data.data['step_data']['step_value'] : null
    },
    SET_FILE_TYPES: (state, { data }) => {
      state.availableFileTypes = data.data['required_files']
    },
    SET_AUTH_CLIENT: (state, client) => {
      state.authClient = true
      localStorage.setItem('auth:client', client)
    },
    CLEAR_AUTH_CLIENT: (state) => {
      state.authClient = false
      localStorage.removeItem('auth:client')
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = [...permissions]
    }
  },
  actions: {
    async CREATE_USER ({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const res = axios.post(
          `${process.env.VUE_APP_API_URL}api/clients/create`,
          payload
        )

        resolve(res)
      })
    },
    async UPDATE_USER ({ _, state }, { fields }) {
      const sendData = {}

      if (fields.length) {
        fields.forEach( f => {
          sendData[f['relation_field']['relation']] = {
            ...sendData[f['relation_field']['relation']],
            [f['relation_field']['column'] || f.id]: f.value
          }
        })
      }

      return await apiRequest.function(
        'PATCH',
        `api/clients/update?step=${state.step}`,
        ['SET_STEP'],
        null,
        sendData,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async SEND_PASS ({ commit }, { fields }) {
      return new Promise((resolve) => {
        const res = axios.patch(
          `${process.env.VUE_APP_API_URL}api/clients/passport_data`,
          fields,
          {
            headers: {
              Authorization: 'Bearer ' + this.getters.TOKEN
            }
          }
        ).catch((error) => {
          if (error.response.status == 422) {
            this.dispatch('addNotification', { message: error.response.data.message })
          }
          resolve(error.response)
        })

        resolve(res)
      })

      return await apiRequest.function(
        'PATCH',
        'api/clients/passport_data',
        null,
        null,
        fields,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async SEND_ADDRESS ({ commit }, { fields }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}api/clients/address`, fields, {
            headers: {
              Authorization: 'Bearer ' + this.getters.TOKEN
            }
          })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            resolve(error.response)
          })
      })

      return await apiRequest.function(
        'POST',
        'api/clients/address',
        null,
        null,
        fields,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async UPLOAD_PHOTO ({ commit }, { files }) {
      const res = await apiRequest.function(
        'POST',
        'api/files/add',
        null,
        null,
        files,
        {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getters.TOKEN}`
        }
      )

      return res
    },
    async UPLOAD_PHOTO_AUTH ({ commit }, { fields }) {
      const res = await apiRequest.function(
        'POST',
        'api/files/append/auth',
        null,
        null,
        fields,
        {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getters.TOKEN}`
        }
      )

      return res
    },
    async DELETE_PHOTO ({ commit }, id) {
      if (id !== '') {
        const res = await apiRequest.function(
          'DELETE',
          `api/files/delete/${id}/auth`,
          null,
          null,
          null,
          {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.getters.TOKEN}`
          }
        )

        return res
      }

      return true
    },
    async REPLACE_PHOTO ({ commit }, { id, fields }) {
      const res = await apiRequest.function(
        'POST',
        `api/files/update/${id}/auth`,
        null,
        null,
        fields,
        {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getters.TOKEN}`
        }
      )

      return res
    },
    async SEND_JOB_DATA ({ commit }, { fields }) {
      return await apiRequest.function(
        'POST',
        'api/clients/createJobData',
        null,
        null,
        fields,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_BIND_CARD_LINK ({ commit }, referer) {
      return await apiRequest.function(
        'GET',
        `api/pay/link?referer=${referer}`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_BIND_CARD_LINK_AUTH ({ commit }, referer) {
      return await apiRequest.function(
        'GET',
        `api/pay/link?referer=${referer}`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async SET_CLIENT_SERVICES ({ commit }, { insurance }) {
      return await apiRequest.function(
        'POST',
        'api/clients/services',
        null,
        null,
        { dok: false, rejection_reason: false, insurance },
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_BIND_CARD_CALLBACK ({ commit }, { search }) {
      const res = await apiRequest.function(
        'POST',
        `api/pay/callback/best2pay${search}`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )

      if (res === undefined) return
      const { data, code } = res.data

      if (code === 200) {
        commit('SET_CARD', res.data)
      }

      return res
    },
    async SEND_BID ({ commit }, { fields, url = '' }) {
      return new Promise((resolve) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}api/orders/store${url}`, fields, {
            headers: {
              Authorization: 'Bearer ' + this.getters.TOKEN
            }
          })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            resolve(error)
          })
      })

      return await apiRequest.function(
        'POST',
        'api/orders/store',
        null,
        null,
        fields,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_STEP ({ commit }) {
      const res = await apiRequest.function(
        'GET',
        'api/clients/step',
        ['SET_STEP'],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )

      if (res?.status === 404 || res?.data?.code === 404) {
        window.location.href = '/'
      }

      return res
    },
    async BID_VERIFY ({ commit }, payload) {
      const res = await apiRequest.function(
        'POST',
        'api/orders/verify',
        null,
        null,
        payload,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )

      return res
    },
    async GET_SETTINGS ({ commit }, ident) {
      const res = await apiRequest.function(
        'GET',
        'api/settings/get',
        ['SET_FILE_TYPES'],
        { 'ident': ident },
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )

      if (res?.status === 404 || res?.data?.code === 404) {
        window.location.href = '/'
      }

      return res
    },
    async GET_LAST_REFERER () {
      return await apiRequest.function(
        'GET',
        'api/clients/last_referer',
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async SET_FIELDS_ERROR ( { rootState }, errors ) {
      Object.entries(errors).forEach((error) => {
        const splitErrorFields = error[0].split('.')
        const splitErrorField = splitErrorFields[1]
        const splitErrorGroup = splitErrorFields[0]

        rootState.configurations.forEach( step => {
          if (step.type === 'identity') {
            step.items.forEach( g => {

              if (g.items.length) {
                g.items.forEach( (field, i) => {
                  if (field['relation_field'] && field['relation_field']['column'] === splitErrorField && field['relation_field']['relation'] === splitErrorGroup) {
                    g.items[i]['error'] = [error[1][0]]
                  }
                  if (field['relation_field']?.['relation'] === 'extended' && field['relation_field']['field_id'] === +splitErrorField) {
                    g.items[i]['error'] = [error[1][0]]
                  }
                })
              }
              else{
                if(['registration_address', 'residence_address'].indexOf(splitErrorGroup) > -1){
                  g['error'] = [error[1][0]]
                }
              }
            })
          }
        })
      })
    },
    async INCREASE_STEP ( { state, commit }, value ) {
      await commit('SET_STEP', { data: { data: { 'step_data': { 'step_value': value ? state.step + 1 : state.step - 1 } } } })
    },
  },
  getters: {
    CARDS (state) {
      return state.cards
    },
    STEP (state) {
      return state.step
    },
    AVAILABLE_FILE_TYPES (state) {
      return state.availableFileTypes
    },
    TOKEN (state) {
      if (typeof state.token === 'undefined') {
        return null
      } else return state.token
    },
    IS_AUTH (state) {
      return state.authClient
    },
    USER_ID (state) {
      return state.user_id
    },
    PERMISSIONS (state) {
      return state.permissions
    },
    GET_IDENTITY_FIELD_BY_STEP (state, getters) {
      if (getters['STEP'] === null || (getters['STEP'] && getters['IDENTITY_CONFIGURATION'].length < getters['STEP'])) {
        return []
      }

      const stepGroups = getters['IDENTITY_CONFIGURATION'][getters['STEP']]
      const formBuilder = getters['FORM_FIELDS']

      stepGroups.items.forEach( group => {
        if (group.tag.split('_') !== 'series') {
          if (getters['USER'].isExistPassword) {
            group.items = group.items.filter( field => field['relation_field']?.['column'] !== 'password')
          }
          group.items.forEach( field => {
            field.value = null
            if (field['relation_field']) {
              if (field['relation_field']['relation'] === 'client') {
                field.value = getters['USER'][field['relation_field']['column']]
              }
              if (field['relation_field']['relation'] === 'extended') {
                getters['USER']['extended'].forEach( el => {
                  if (el['field_id'] === field.id) {
                    field.value = el.value
                  }
                })
              }
              if (!['client', 'extended'].includes(field['relation_field']['relation']) && getters['USER'][field['relation_field']['relation']]) {
                field.value = getters['USER'][field['relation_field']['relation']][field['relation_field']['column']]
              }
            }
            field.required = formBuilder.filter( f => f.id === field.id)[0]?.['required'] || false
            field.name = field.name.replace(/.\(.+\)/,'')
          })
        }
      })

      return stepGroups
    },
  }
}

export default signup
